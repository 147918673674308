<template>
  <v-avatar class="ai-bot-visualization" :class="{'--disabled': disabled !== false}" :size="displaySize" color="#040227">
    <v-img :class="`--${animation}`" :src="botImage" />
  </v-avatar>
</template>

<script lang="ts" setup>
import bot50 from './images/bot-50.webp';
import bot100 from './images/bot-100.webp';
import bot250 from './images/bot-250.webp';
import {ComputedRef, PropType} from 'vue';
import {AIAnimation} from '~/@types/ai';

// PROPS Definitions
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  animation: {
    type: String as PropType<AIAnimation>,
    default: null,
  },
  size: {
    type: [Number , String],
    default: 40,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

/**
 * Get Display size
 */
const displaySize: ComputedRef<number> = computed(() => {
  let size = typeof props.size === 'string' ? parseInt(props.size) : props.size;
  if (props.animation === 'heart-beat') {
    size += 10;
  }
  return size;
});

/**
 * Get bot image based on size
 */
const botImage: ComputedRef<string> = computed(() => {
  if (props.size <= 50) {
    return bot50;
  } else if (props.size <= 100) {
    return bot100;
  } else {
    return bot250;
  }
});
</script>

<style lang="scss">
.bot-enter-active,
.bot-leave-active {
  transition: opacity 0.3s ease,
  scale 0.6s ease;
}

.bot-enter-from,
.bot-leave-to {
  opacity: 0;
  scale: 0;
}

.ai-bot-visualization {
  &.--disabled {
    opacity: 0.7;
    filter: grayscale(100%);
  }

  .v-img {
    cursor: pointer;
    scale: 0.8;
    object-fit: cover;

    &.--heart-beat {
      animation: animateHeart 1.5s infinite;
    }

    &.--spinning {
      animation-name: spin;
      animation-duration: 6000ms;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(0.9);
  }
  5% {
    transform: scale(1.1);
  }
  10% {
    transform: scale(0.9);
  }
  15% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.9);
  }
}
</style>
